import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../Context/Theme/Context";
import certificate from '../images/certificate.jpg'
import certificate2 from '../images/certificate2.jpg'
import certificate3 from '../images/certificate3.jpg'
import AboutUsBanner from "../images/aboutUsBanner.jpg"
import OurHistoryBanner from "../images/our-history.jpg"
import QualityBanner from "../images/qualityBanner.jpg"
import qualityBannerImg from "../images/qualityBannerImg.jpg"
import aboutBigBanner from "../images/aboutbigBanner.jpg"
import { NavLink } from "react-router-dom";
// import Wrapper from "./Wrapper";
// import { NavLink } from "react-router-dom";
// import { Button, Input } from "../Library/Module";

interface HomeProps {
    link: string;
}

type formDataProps = {
    email_id: string;
    full_name: string;
    business_name?: string;
    phone_number?: string;
    commnet?: string;
};


const ContactUs = ({ link }: HomeProps): JSX.Element => {
    const { orgUser } = useContext(ThemeContext)
    const [disabled, setDisabled] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted]: any = useState(false);
    const [formData, setFormData] = useState<formDataProps>({
        full_name: '',
        business_name: '',
        email_id: '',
        phone_number: '',
        commnet: ""
    });
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState) => ({
            ...prevState,
            ...data,
        }));
        setIsFormSubmitted(false);
    };

    const Submit = () => {
        alert("Form has been submitted successfully")
    }

    useEffect(() => {
        if (formData?.full_name && formData?.full_name?.length > 0 &&
            formData?.email_id && formData?.email_id?.length > 0) {
            setDisabled(false)
        }

    }, [formData])

    return (
        <>
            <div className="sliderMain">
                <img className="imageSlider" src={AboutUsBanner} alt="" title="" />
            </div>

            <div className="container pt-4 pb-4">
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-md-6 col-sm-12">
                        <img src={certificate} alt="" title="" />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <p>National Homoeo Laboratory is a renowned manufacturer of high-quality homoeopathic medicines, with a rich history spanning over seven decades. Specializing in the production of classical homoeopathic remedies, the company excels in preparing Mother Tinctures and potentized dilutions, which are the primary constituents of homoeopathic medicines. Renowned for their unparalleled quality, National Homoeo Laboratory's products are highly sought after by homoeopathic physicians, distributors, and consumers alike.</p>
                        <p>Established in 1950, National Homoeo Laboratory has built a strong reputation for its commitment to excellence and adherence to the highest standards of manufacturing. The company's state-of-the-art facilities and rigorous quality control measures ensure that each batch of medicine meets the stringent requirements of the Homoeopathic Pharmacopoeia. With a team of experienced professionals and a deep understanding of homoeopathic principles, National Homoeo Laboratory continues to set the benchmark for quality and consistency in the industry.</p>
                        <p>As a leading manufacturer of homoeopathic medicines, National Homoeo Laboratory is dedicated to promoting the benefits of homoeopathy and making high-quality remedies accessible to people across India. Through its extensive distribution network and partnerships with reputable homoeopathic physicians and retailers, the company ensures that its products reach the hands of those seeking natural and effective healthcare solutions.</p>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-md-6 col-sm-12">
                        <h3>Our History</h3>
                        <p>Commencing its journey seven decades ago, with just one individual operating from a modest room in a narrow Kolkata by-lane, the inception of manufacturing Homoeopathic Globules by Usha Ranjan Sinha Roy in the 1950s marked the humble beginnings of an extraordinary venture. </p>
                        <p>Over time, this modest setup has evolved into one of the most distinguished establishments in India, a testament to the visionary leadership it has been fortunate to have.</p>
                        <p>Under the accomplished guidance of Late Mr. Ranjit Sinha Roy and the pioneering efforts of the late Usha Ranjan Sinha Roy, the company experienced significant growth and transformation. Their dedication and vision laid the foundation for what has become a beacon of excellence in the realm of Homoeopathic medicines. </p>
                        <p>Today, the legacy continues under the capable leadership of Mr. Biswajit Sinha Roy, who carries forward the rich heritage left behind by Late Ranjit Sinha Roy.</p>
                        <p>This remarkable journey reflects not only the resilience and entrepreneurial spirit that initiated the venture but also the sustained commitment to excellence and innovation that has propelled it to its current esteemed status in the field of Homoeopathic medicine manufacturing in India.</p>
                    </div>
                    <div className="col-md-6 col-sm-12 text-right">
                        <img src={OurHistoryBanner} alt="" title="" />
                    </div>
                </div>
            </div>
            <div className="sliderMain mb-4">
                <img className="imageSlider" src={QualityBanner} alt="" title="" />
            </div>
            <div className="container pt-4 pb-4">
                <div className="row row justify-content-center align-items-center">
                    <div className="col-md-6 col-sm-12 text-center">
                        <img src={qualityBannerImg} alt="" title="" />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <h3>Our Quality Control</h3>
                        <p>Our traditional Hahnemannian process of medicine manufacturing, backed by extensive research in India, yields world-class homeopathic medicines. The quality control team ensures that all products meet international standards. The entire factory area is meticulously maintained to be dust-free, with Class A Air Handling systems in place and automated machines in strategic locations. Additionally, strict safety measures are implemented throughout the facility to guarantee the highest level of quality in every step of the manufacturing process, which is carried out manually to ensure precision and attention to detail.</p>
                        <h3>QUALITY CERTIFICATIONS</h3>
                        <p>National has the certification of ISO 9001:2000. We have also acquired the GMP certificate - the ultimate quality standard in medicine. </p>
                        <p>The company organises Homoeopathic Doctor's conference at different venues for the betterment of homoeopathic practices & research.</p>
                    </div>

                </div>
            </div>

            <div className="publications">
                <div className="container pt-4 pb-4">
                    <div className="row row justify-content-center align-items-center mb-3">
                        <div className="col-md-12 col-sm-12 text-center">
                            <img src={aboutBigBanner} alt="" title="" /> </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <p>National Homoeo Laboratory publishes several homeopathic books in English, Hindi, and Bengali and these are listed below. Our publications some of which aren extreamery rare are available in limited numbers and can be purchased directly from us.</p>
                        <ul className="listPublications">
                            <li>Dr. Kent's lectures on Homoeopathic Materia Medica with new Remedies  & Therapeutic Index</li>

                            <li>Dr. Kent's Philosophy with Hints to the Doctor</li>

                            <li>Dr. Boericke's Pocket Manual of Homoeopathic Materia Medica with Repertory</li>

                            <li>Dr. Bhanja's Master Key to Homoeopathic Materia Medica</li>

                            <li>Dr. Bhanja's The Homoeopathic Prescriber</li>
                            <div className="clearfix"></div>
                        </ul>
                        <h3 style={{ marginLeft: '2.5%' }}>In Hindi, we publish:</h3>
                        <ul className="listPublications">
                            <li> Homoeopathic Griha Chikitsa</li>
                            <li>Dr. Boericke's Materia Medica with Repertory</li>

                            <li>Dr. Boericke & Dewey's The Twelve Tissues Remedies of Schussler</li>
                            <div className="clearfix"></div>


                        </ul>
                        <h3 style={{ marginLeft: '2.5%' }}>In Bengali, we publish:</h3>
                        <ul className="listPublications">
                            <li> Dr. Boericke's Materia Medica with Repertory</li>
                            <li> Homoeopathic Griha Chikitsa</li>
                            <li> Dr. Abhoypada Chattopadhyay's Sisu Chikitsa</li>
                            <li> Dr. Boericke & Dewey's Biochemic Vaisajya sudhu</li>
                            <li> Dr. Boericke's Organon</li>
                            <li> Dr. Kent's lectures on Meteria Medica</li>
                            <div className="clearfix"></div>
                        </ul>

                        To order these publications, <NavLink to="/contact-us">contact us</NavLink>  today!
                    </div>


                </div>
            </div>

        </>
    );
};
export default ContactUs;
